<template lang="pug">
.custom-scrollbar
  .custom-scrollbar__inner
    slot
  .custom-scrollbar__bar
    .custom-scrollbar__knob
</template>

<script>
export default {
  name: "CustomScroll",
  methods: {
    init() {
      const knob = document.querySelector('.custom-scrollbar__knob')
      const bar = document.querySelector('.custom-scrollbar__bar')
      const container = document.querySelector('.custom-scrollbar__inner')

// When the container is scrolled
      container.addEventListener('scroll', () => {
        // If we are dragging the knob, do nothing
        if (dragging) return

        // Otherwise, set the knob position based on the scroll position
        knob.style.top = container.scrollTop / (container.scrollHeight - container.offsetHeight) * 100 + '%'
      })

      let dragging = false
      knob.addEventListener('mousedown', (event) => {
        dragging = {
          x: event.clientX,
          y: event.clientY
        }
      })
      window.addEventListener('mousemove', (event) => {
        if (dragging) {
          // When dragging
          event.preventDefault()
          const diff = {
            x: event.clientX - dragging.x,
            y: event.clientY - dragging.y
          }

          // Clamp the position of the knob to be a maximum of
          // the knobs container, and a minimum of 0
          let newTop = Math.max(0, Math.min(knob.offsetTop + diff.y, bar.offsetHeight))
          knob.style.top = newTop + 'px'

          // Base the scroll offset on the knobs position
          // in relation to the knobs container
          container.scrollTop = newTop / bar.offsetHeight * (container.scrollHeight - container.offsetHeight)

          dragging = {
            x: event.clientX,
            y: event.clientY
          }
        }
      })
      window.addEventListener('mouseup', () => {
        dragging = false
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="sass">
.custom-scrollbar
  position: relative
  overflow: hidden

.custom-scrollbar__inner
  height: 100%
  width: calc(100% + 40px) // Larger than container
  padding-right: 64px // Scrollbar hangs out in this padding
  overflow-y: auto
  -webkit-overflow-scrolling: touch

.custom-scrollbar__bar
  position: absolute
  top: 16px
  bottom: 16px
  background: #000000
  width: 4px
  right: 20px
  border-radius: 4px

.custom-scrollbar__knob
  position: absolute
  top: 0
  left: 50%
  width: 32px
  height: 10px
  border-radius: 10px
  background: #efdf00
  transform: translate(-50%, -50%)
  will-change: top

</style>