<template lang="pug">
.pick-up-location-wrapper
  CloseCircle(@click="$emit('close', false)")
  .pick-up-location-title {{ $t("stepGeneral.selectCollection") }}
  .pick-up-location-container
    div(v-if="pickUpLocations.length < 8")
      label.pick-up-location(v-for="(location, index) in pickUpLocations" :key="index" )
        input(:id="location.id" type="radio" @change="$emit('input', location.id)" :checked="location.id === value" :value='location.id.toString()' v-validate="'required'" data-vv-name="location")
        div.selected-bg
        div.pick-up-location-textbox(:class="{'selected': location.id === value}")
          div
            div
              b {{ location.name }} {{ location.dealer ? '- ' + location.dealer : '' }}
          div
            b {{ $t('select') }}
    CustomScroll.location-custom-scroll(v-else)
      label.pick-up-location(v-for="(location, index) in pickUpLocations" :key="index" )
        input(:id="location.id" type="radio" @change="$emit('input', location.id)" :checked="location.id === value" :value='location.id.toString()' v-validate="'required'" data-vv-name="location")
        div.selected-bg
        div.pick-up-location-textbox(:class="{'selected': location.id === value}")
          div
            div
              b {{ location.name }} {{ location.dealer ? '- ' + location.dealer : '' }}
          div
            b {{ $t('select') }}
</template>

<script>
import CustomScroll from "@/components/helps/CustomScroll";

export default {
  name: "SelectPickUpLocation",
  inject: ['$validator'],
  components: {
    CloseCircle: () => import('@/components/steps-faceless/CloseCircle.vue'),
    CustomScroll,
  },
  props: {
    pickUpLocations: [],
    title: {
      type: String,
    },
    value: {
      default: () => null
    },
  },
}
</script>

<style lang="sass">
.pick-up-location-wrapper
  position: absolute
  top: 0
  background: #F7F7F7
  z-index: 99
  padding: 66px 0
  min-width: 567px
  min-height: 900px
  left: -1000%
  transition: left .5s ease-in-out
  &--active
    left: 0
.pick-up-location
  padding: 0 33px 0 63px
  display: flex
  align-items: center
  margin-bottom: 0
  position: relative
  input
    display: none
  .pick-up-location-textbox
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 4px 0
    min-height: 80px
    border-bottom: 0.5px solid #6C6C6C
    div
      position: relative
      z-index: 10
  .pick-up-location-textbox.selected
    border-bottom: transparent
.pick-up-location-title
  margin-bottom: 110px
  text-align: center
  letter-spacing: 0.07em
  text-transform: uppercase
  font-size: 30px

.selected-bg
  position: absolute
  width: 100%
  height: 103%
  left: 0
  top: -2px
  z-index: 9
  background: transparent

.pick-up-location input:checked + .selected-bg
  background: #EFDF00

.pick-up-location:hover .selected-bg
  cursor: pointer
  background: #EFDF00

.pick-up-location input:checked .pick-up-location-textbox
 border: none

.location-custom-scroll
  height: 575px
</style>